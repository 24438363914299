import Papa from "papaparse";
import _forEach from 'lodash/forEach'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import JSZip from 'jszip'

import store from "@/store"
import { tsNowString } from './datetime'
import { LogEvent } from '@/store/analytics'

let debug_key = null

/**
* Compares two objects and returns a "diff" object containing only the differences.
* This is a key helper function for reduceSchema.
* 
* The diff object contains only the properties that:
* - Exist in result but not in std
* - Exist in both but have different values
* - Have nested differences in child objects
* 
* For arrays:
* - Checks if arrays have different elements
* - Handles conversion between arrays and objects with include flags
* 
* The returned diff can be used with Object.assign(std, diff) to recreate the result object
* 
* @param {Object} std - The standard object to compare against
* @param {Object} result - The modified object to extract differences from
* @returns {Object} The minimal set of differences between std and result
*/
export const reduceObject = (std, result) => {
  let debugKey = null
  let log = (result?.key === debugKey);
  if(log) console.log('reduceObject', { std, result })
  let diff = {}
  if(std === null) return result
  if(!std || typeof std !== 'object') {
    LogEvent('system_error', { key: 'schema:reduceObject', details: 'Invalid standard object', std, result })
    return result
  }
  _forEach(result, (v, k) => {
    // if(log) console.log({k, v, std: std[k]})
    if(typeof std[k] === undefined) diff[k] = v
    else if(v && typeof v === 'object') {
      if(Array.isArray(v)) {
        let s = std[k]
        let change = false
        if(!s) change = true
        else if(!Array.isArray(s)) {
          if(v.length === 0 || ([null,''].includes(s))) console.log('reduceObject: skipped empty array equivialent to null value or empty string')
          else if(typeof s !== 'object') {
            console.warn(`Unexpected variable type for ${k}`, { std, val: v })
          }
          else {
            v.forEach((val, key) => { 
              if(!Object.keys(s).includes(key)) change = true
              else if(val.include !== true) change = true 
            })
          }
        }
        else {
          v.forEach((item) => {
            if(!std[k].includes(item)) change = true
          })
          std[k].forEach((item) => {
            if(!v.includes(item)) change = true
          })
        }
        if(change) diff[k] = v
      }
      else {
        let d = reduceObject(std[k], v)
        if(!d) console.log('reduceObject, schema error:', {k, v, std: std[k]})
        if(d && Object.keys(d).length >= 1) diff[k] = d
      }
    }
    else if(std[k] !== v) {
      if(log) console.log('diff', { k, v, std: std[k] })
      diff[k] = v
    }
  })
  if(log) console.log({ diff })
  return diff
}

/**
* Converts a space or comma delimited string to an array
* Removes blank and duplicate values
*/
const dimmToArray = (str) => {
  if(typeof str === 'string') {
    str = str.trim()
    if(str === '') return []
    if(str.includes(',')) return str.split(',')
    return str.split(' ')
  }
  if(!Array.isArray(str)) return str

  let arraySet = new Set()
  str.forEach(s => {
    if(['',' '].includes(s)) return
    arraySet.add(s)
  })
  return Array.from(arraySet)
}

/**
 * Updates the application schema by merging the standard schema with organization-specific modifications.
 * This is triggered when:
 * 1. An organization is bound (via bindOrg action)
 * 2. When checking an Outseta account
 * 3. When the standard schema is updated
*/
export const updateSchema =  _debounce(() => {
    if(!store.state.Db.org || !store.state.Db.config) {
      console.log('schema: waiting on source data')
      setTimeout(updateSchema, 1000)
      return
    }

    // PARSE ORG SCHEMA
    // Create schema object, handling various legacy formats  
    let orgSchema = {
      types: {},
      templates: {},
      attributes: {},
      lists: {},
      groups: []
    }
    
    // Parse org schema
    let dbOrgSchema = store.state.Db.org?.schema || {}
    if(typeof dbOrgSchema === 'string') dbOrgSchema = JSON.parse(dbOrgSchema)
    
    // Parse types, handling legacy string format
    if(typeof dbOrgSchema.types === 'string') orgSchema.types = JSON.parse(dbOrgSchema.types)
    else if (typeof dbOrgSchema.types === 'object') orgSchema.types = dbOrgSchema.types

    // Parse templates, handling legacy string format
    if(typeof dbOrgSchema.templates === 'string') orgSchema.templates = JSON.parse(dbOrgSchema.templates)
    else if (typeof dbOrgSchema.templates === 'object') orgSchema.templates = dbOrgSchema.templates
    
    // Parse legacy attribute definitions
    // Convert legacy 'attributes' Array into an object format
    _forEach(orgSchema.types, (t, k) => { 
        if(typeof t.addAttrib === 'string') t.addAttrib = JSON.parse(t.addAttrib)
        
        if(Array.isArray(t.addAttrib)) {
            let addAttrib = {}
            t.addAttrib.forEach(o => {
                let key = o.tak || o.key || o
                addAttrib[key] = { include: 'Y' }
            })
            t.addAttrib = addAttrib
        }
    })
    _forEach(orgSchema.templates, (t, k) => { 
        if(typeof t.attributes === 'string') t.attributes = JSON.parse(t.attributes)
        
        if(Array.isArray(t.attributes)) {
            let attributes = {}
            t.attributes.forEach(o => {
                let key = o.tak || o.key || o
                attributes[key] = { include: 'Y' }
            })
            t.attributes = attributes
        }
    })

    // Parse attributes, handling legacy formats and creating index-based ordering
    if(typeof dbOrgSchema.attributes === 'string') orgSchema.attributes = JSON.parse(dbOrgSchema.attributes)
    if(Array.isArray(dbOrgSchema.attributes)) {
        dbOrgSchema.attributes.forEach((i, a) => {
            let key = i.key
            let tak = i.tak || i.key
            orgSchema.attributes[key] = { ...i, tak, key }
            orgSchema.attributes[key].index = a
        })
    }
    else if (typeof dbOrgSchema.attributes === 'object') orgSchema.attributes = dbOrgSchema.attributes

    // Parse lists, handling both object and array formats
    // Convert options arrays to objects with include flags
    if(typeof dbOrgSchema.lists === 'string') orgSchema.lists = JSON.parse(dbOrgSchema.lists)
    else if (typeof dbOrgSchema.lists === 'object') orgSchema.lists = _cloneDeep(dbOrgSchema.lists)
    if(Array.isArray(orgSchema.lists)) {
        let lists = {}
        orgSchema.lists.forEach(l => {
            let key = l.key || l.tak || l.std_key
            if(l.std_key) {
                l.tak = l.std_key
                delete l.std_key
            }
            lists[key] = l
        })
        orgSchema.lists = lists
    }
    _forEach(orgSchema.lists, (l, k) => { 
        if(typeof l.options === 'string') l.options = JSON.parse(l.options)
        
        if(Array.isArray(l.options)) {
            let options = {}
            l.options.forEach(o => {
                if(typeof o === 'string') o = { value: o, label: o, include: 'Y' }
                if(!o.include) o.include = 'Y'
                options[o.value] = o
            })
            l.options = options
        }
    })
    // Get groups from org setup
    if (Array.isArray(dbOrgSchema.setup?.groups)) orgSchema.groups = dbOrgSchema.setup.groups
    
    // Parse standard schema
    let dbTrakkSchema = store.state.Db.config?.schema?.assets || {}
    let trakkSchema = {
        attributes: dbTrakkSchema.attributes,
        templates: dbTrakkSchema.templates,
        types: dbTrakkSchema.types,
        lists: dbTrakkSchema.lists,
        groups: dbTrakkSchema.groups,
        common: dbTrakkSchema.common || ['label', 'position', 'amsid', 'ds_inst', 'ds_ret', 'ds_exp', 'life_ya']
    }
    _forEach(trakkSchema, (o, k) => { 
      if(typeof o === 'string') trakkSchema[k] = JSON.parse(o)
    })
    _forEach(trakkSchema.groups, (g, k) => { 
        if(typeof g.types === 'string') g.types = JSON.parse(g.types)
    })
    
    console.log('DEPRECIATE l.options array in v5')
    _forEach(trakkSchema.lists, (l, k) => { 
        if(typeof o === 'string') trakkSchema.lists[k] = JSON.parse(l)
        
        if(Array.isArray(l.options)) {
            console.log('WARNING found depreciated l.options as array')
            let options = {}
            l.options.forEach(o => {
                if(typeof o === 'string') o = { value: o, label: o, include: 'Y' }
                if(!o.include) o.include = 'Y'
                options[o.value] = o
            })
            l.options = options
        }
    })
    console.log('DEPRECIATE a.addAttrib array in v5')
    _forEach(trakkSchema.types, (t, k) => {
        if(Array.isArray(t.addAttrib)) {
            console.log('WARNING found depreciated a.addAttrib as array')
            let addAttrib = {}
            t.addAttrib.forEach(a => {
                let tak = a.tak || a
                addAttrib[tak] = { include: 'Y' }
            })
            t.addAttrib = addAttrib
        }
    })
    console.log('DEPRECIATE t.attributes array in v5')
    _forEach(trakkSchema.templates, (t, k) => {
        if(Array.isArray(t.attributes)) {
            console.log('WARNING found depreciated t.attributes as array')
            let attributes = {}
            t.attributes.forEach(a => {
                let tak = a.tak || a
                attributes[tak] = { include: 'Y' }
            })
            t.attributes = attributes
        }
    })

    // Merge schemas and commit to store
    const schema = mergeSchema(trakkSchema, dbOrgSchema)
    store.commit('SET_SCHEMA', schema)

}, 500, { maxwait: 2000 })

/**
 * Merges a standard schema with organization-specific modifications to create a final working schema.
 * 
 * @param {Object} stdSchema - The standard "Trakk" schema that defines the default structure
 * @param {Object} orgSchema - Organization-specific schema modifications
 * @returns {Object} The merged schema containing:
 *   - types: Asset types filtered by selected groups
 *   - templates: Templates used by the selected types
 *   - lists: Selection lists referenced by attributes
 *   - attributes: All attributes used across templates and types
 */
export const mergeSchema = (stdSchema, orgSchema) => {
    stdSchema = _cloneDeep(stdSchema)
    orgSchema = _cloneDeep(orgSchema || {})
    let schema = {
        types: {},
        templates: {},
        lists: {},
        attributes: [],
        reports: {}
    }

    // SET SETUP PARAMETERS
    // Get setup parameters from org and apply defaults
    if(orgSchema.setup) {
        schema.setup = _cloneDeep(orgSchema.setup)
        if(!schema.setup.srs) schema.setup.srs = 'EPSG:4326'
        if(!schema.setup.imageSize) schema.setup.imageSize = '12'
        if(!schema.setup.imageCompress) schema.setup.imageCompress = 0.75
        if(!schema.setup.idSetup) schema.setup.idSetup = ['manual', 'auto', 'nfc-uid']
        if(!schema.setup.idType) schema.setup.idType = 'AssetID'
    }
    else {
        schema.setup = { 
            idType: 'AssetID', 
            idSetup: ['manual', 'auto', 'nfc-uid'],
            idAuto: 'up',
            imageSize: '12',
            imageCompress: 0.75,
            srs: 'EPSG:4326'
        }
    }
    if(!orgSchema.reports) orgSchema.reports = {}

    // TODO: remove hardcoded schema data once it is loaded
    // add hardcoded standards schema data
    let hardReports = {
      note: {
        label: 'Note',
        attributes: {
          title: { label: 'Subject', required: true },
          description: {},
          _ts: { required: true, type: 'datetime' },
        },
        fields: [
          { key: 'title' },
          { key: 'description' },
          { key: '_ts' }
        ],
        allowFiles: true
      },
      fault: {
        label: 'Fault Report',
        attributes: {
          title: { label: 'Subject', required: true },
          description: {},
          _ts: { required: true, type: 'datetime' },
        },
        fields: [
          { key: 'title' },
          { key: 'description' },
          { key: 'note_rsp' },
          { key: '_ts' }
        ],
        allowNotes: true,
        allowFiles: true
      },
      condition: {
        label: 'Condition Report',
        attributes: {
          rating: { required: true },
          _ts: { required: true, type: 'datetime' },
        },
        fields: [
          { key: 'rating' },
          { key: 'description' },
          { key: '_ts' }
        ],
        allowFiles: true
      },
      task_reactive: {
        label: 'Reactive Task',
        attributes: {
          _ts: { required: true, type: 'date', label: 'Task Due' },
          _user: { required: true, label: 'Task Assigned to' },
          title: { label: 'Subject' },
          rating: { required: true },
          description: { label: 'Briefing' },
          _status: { required: true },
        },
        fields: [
          { key: '_userID' },
          { key: '_ts' },
          { key: 'title' },
          { key: 'description' },
          { key: 'note_rsp' },
          { key: 'ts_complete' },
          { key: '_status' },
        ],
        allowFiles: true,
        allowNotes: true
      },
      task_scheduled: {
        label: 'Scheduled Task',
        attributes: {
          title: { label: 'Subject', required: true },
          _ts: { required: true, type: 'date', label: 'Task Due' },
          _status: { required: true },
          _userID: { required: true },
          ts_complete: { required: true },
        },
        fields: [
          { key: '_userID' },
          { key: '_ts' },
          { key: 'title' },
          { key: 'description' },
          { key: 'note_rsp' },
          { key: 'ts_complete' },
          { key: '_status' },
        ],
        allowFiles: true,
        allowNotes: true,
        recurring: '6m'
      },
      task_bwof_iqp: {
        label: 'Building Inspection (IQP)',
        attributes: {
          _ts: { required: true, type: 'date', label: 'Task Due' },
          title: { label: 'Subject' },
          note_rsp: { copy: false },
          typ_bwof_nz: { multiple: true, label: 'Systems Checked' },
          compliant_iqp: { 
            copy: false, type: 'check-dyn', setup: 'typ_bwof_nz', style: 'pass-fail', label: 'Compliant Systems (PASS)',
            description: "Confirm compliance of selected building systems, remove systems not assessed",
          },
        },
        fields: [
          { key: 'title' },
          { key: 'description' },
          { key: '_ts' },
          { key: '_userID' },
          { key: 'typ_bwof_nz' },
          { type: '_rule', label: 'Compliance Check' },
          { key: 'user_iqp' },
          { key: 'compliant_iqp' },
          { type: '_rule' },
          { key: 'note_rsp' },
          { key: 'ts_complete' },
          { key: '_status' }
        ],
        allowFiles: true,
        recurring: '365d'
      },
      task_bwof_insp: {
        label: 'Building Inspection',
        attributes: {
          _ts: { required: true, type: 'date', label: 'Task Due' },
          title: { label: 'Subject' },
          typ_bwof_nz: { multiple: true, label: 'Systems Checked' },
          compliant: { 
            copy: false, type: 'check-dyn', setup: 'typ_bwof_nz', style: 'pass-fail', label: 'Compliant Systems (PASS)',
            description: "Confirm compliance of selected building systems, remove systems not assessed",
          },
          note_rsp: { copy: false },
        },
        fields: [
          { key: 'title' },
          { key: 'description' },
          { key: '_ts' },
          { key: '_userID' },
          { key: 'typ_bwof_nz' },
          { type: '_rule', label: 'Compliance Check' },
          { key: 'compliant' },
          { type: '_rule' },
          { key: 'note_rsp' },
          { key: 'ts_complete' },
          { key: '_status' }
        ],
        allowFiles: true,
        recurring: '1m'
      },
    }
    let hardAttributes = [
    ]
    let hardLists = {
      priority: {
        label: 'Priority',
        options: [
          { value: '1', label: 'Low' },
          { value: '2', label: 'Medium' },
          { value: '3', label: 'High' }
        ]
      },
      ynuk: {
        label: 'Yes / No / Unknown',
        options: [
          { value: 'Y', label: 'Yes' },
          { value: 'N', label: 'No' },
          { value: 'UNK', label: 'Unknown' }
        ],
      },
      typ_bwof_nz: {
        label: 'BWOF Compliance System Types',
        options: [
          { value: "nz_ss01", label: "SS 01 Fire Suppression", description: "Automatic systems for fire suppression" },
          { value: "nz_ss02", label: "SS 02 Emergency Warning", description: "Automatic or manual emergency warning systems for fire or other dangers" },
          { value: "nz_ss02-1", label: "SS 02/1 Simple Fire Alarm", description: "Simple Fire Alarm Systems" },
          { value: "nz_ss02-2", label: "SS 02/2 Complex Fire Alarm", description: "Complex Fire Alarm Systems" },
          { value: "nz_ss02-3", label: "SS 02/3 Warning Other Dangers", description: "Warning Systems for dangers other than fire" },
          { value: "nz_ss03-1", label: "SS 03/1 Automatic Doors", description: "Automatic doors that could cause injury or trap occupants" },
          { value: "nz_ss03-2", label: "SS 03/2 Access Controlled Doors", description: "Access Controlled Doors that could trap occupants" },
          { value: "nz_ss03-3", label: "SS 03/2 Fire, Smoke Doors", description: "Interfaced fire or smoke doors or windows, designed to open or close automatically" },
          { value: "nz_ss04", label: "SS 04 Emergency Lighting", description: "Emergency lighting systems. EG: for identification of pathways or exits" },
          { value: "nz_ss05", label: "SS 05 Excape Pressure", description: "Escape route pressurisation systems" },
          { value: "nz_ss06", label: "SS 06 Riser Mains", description: "Water riser main for use by fire services" },
          { value: "nz_ss07", label: "SS 07 Backflow Preventers", description: "Automatic back-flow preventers connected to a potable water supply" },
          { value: "nz_ss08-1", label: "SS 08/1 Passenger Lifts", description: "Passenger-carrying lifts or elevator" },
          { value: "nz_ss08-2", label: "SS 08/2 Service Lifts", description: "Platform, low-speed and service lifts. EG: dumb waiter, vehicle stacking, stage lift" },
          { value: "nz_ss08-3", label: "SS 08/3 Escalators", description: "Escalators and travelators (moving walks)" },
          { value: "nz_ss09", label: "SS 09 Ventilation or Air Conditioning", description: "Mechanical ventilation or air conditioning systems" },
          { value: "nz_ss10", label: "SS 10 Maintenance Units", description: "Building maintenance unit (mechanical, electrical or hydraulic). EG: gantry" },
          { value: "nz_ss11", label: "SS 11 Fume Cupboards", description: "Laboratory Fume cupboard with ducted extraction system." },
          { value: "nz_ss12-1", label: "SS 12/1 Audio Loops", description: "Audio loops for assistive listening" },
          { value: "nz_ss12-2", label: "SS 12/2 Transmission System", description: "FM radio frequency systems & infra-red beam transmission systems" },
          { value: "nz_ss13-1", label: "SS 13/1 Mechanical Smoke Control", description: "Mechanical system designed to control or discharge smoke" },
          { value: "nz_ss13-2", label: "SS 13/2 Natural Smoke Control", description: "Natural / passive smoke control system using natural smoke buoyancy" },
          { value: "nz_ss13-2", label: "SS 13/2 Smoke Curtains", description: "Designed to control movement of smoke through building" },
          { value: "nz_ss14-1", label: "SS 14/1 Emergency Power", description: "Systems supplying emergency power to any of the specified systems" },
          { value: "nz_ss14-2", label: "SS 14/2 Signs", description: "Signs relating to a system or feature specified in any of clauses 1 to 13" },
          { value: "nz_ss15-1", label: "SS 15/1 Communication", description: "Systems for communicating spoken information intended to facilitate evacuation" },
          { value: "nz_ss15-2", label: "SS 15/2 Final Exits", description: "Final exits. Doors or gates providing exit to street or between evacuation zones" },
          { value: "nz_ss15-3", label: "SS 15/3 Fire Separations", description: "Fire separations. EG: fire door, fire rated floor, wall for safe path" },
          { value: "nz_ss15-4", label: "SS 15/4 Evacuation Signs", description: "Signs for communicating information intended to facilitate evacuation" },
          { value: "nz_ss15-5", label: "SS 15/5 Smoke Separations", description: "Smoke separations. EG: smoke stop door, wall for safe path, smoke resistant lift" },
          { value: "nz_ss16", label: "SS 16 Cable Cars", description: "Any cable car. EG: for building service, lifting or moving passengers" },
          { value: "nz_ss_M", label: "M Means of escape", description: "Means of escape from fire Building Act 1991" },
          { value: "nz_ss_N", label: "N Safety barriers", description: "Safety barriers Building Act 1991" },
          { value: "nz_ss_O", label: "O Disability access", description: "Means of access and facilities for use by persons with disabilities Building Act 1991" },
          { value: "nz_ss_P", label: "P Hose Reels", description: "Hand held hose reels for fire fighting Building Act 1991" },
          { value: "nz_ss_Q", label: "Q Signs required", description: "Signs required by the building code or Section 47A of the Building Act 1991" },
          { value: "nz_ss_J", label: "J Other systems from Building Act", description: "Other Systems as set out in Building Act 1991 sections 44 and 45 - identify" },
        ]
      }
    }

    // HARDCODED DATA OVERRIDES standard schema
    if(!stdSchema.reports) stdSchema.reports = {}
    _forEach(hardReports, (r, k) => {
      // if(!stdSchema.reports[k]) stdSchema.reports[k] = r
      stdSchema.reports[k] = r
    })
    _forEach(hardAttributes, a => {
      // if(!stdSchema.attributes[a.tak]) stdSchema.attributes[a.tak] = a
      stdSchema.attributes[a.tak] = a
    })
    _forEach(hardLists, (l, k) => {
      // if(!stdSchema.lists[k]) stdSchema.lists[k] = l
      stdSchema.lists[k] = l
    })

    // inject bwof schema using feature flag
    // TODO, check dynamic flag
    if((orgSchema.setup?.features || []).includes('nz_bwof')) {
      [ 'build_name', 'build_location', 'build_unit',
        'address', 'land_legal', 'owner_name', 'owner_contact', 
        'owner_mail', 'owner_address', 'owner_office', 'typ_bwof_nz'].forEach((key) => {
        if(orgSchema.templates.bld) orgSchema.templates.bld.attributes[key] = { include: 'Y'}
        stdSchema.templates.bld.attributes[key] = { include: 'Y'}
      });
      [ 'address', 'land_legal', 'owner_name', 'owner_contact', 
        'owner_mail', 'owner_address', 'owner_office'].forEach((key) => {
        if(orgSchema.templates.bld) orgSchema.templates.site.attributes[key] = { include: 'Y'}
        stdSchema.templates.site.attributes[key] = { include: 'Y'}
      })
      // console.log({ std: stdSchema.templates.site, org: orgSchema.templates.site})
    }


    // Handle legacy list data, converting arrays into objects
    // First convert array of list into object
    let convertListArray = (schema) => {
      let listObject = {}
      schema.lists.forEach((item, index) => {
        listObject[item.key] = item
      })
      schema.lists = listObject
    }
    if(Array.isArray(stdSchema.lists)) convertListArray(stdSchema)
    if(Array.isArray(orgSchema.lists)) convertListArray(orgSchema)
    
    // Convert lists options into objects
    _forEach(stdSchema.lists, (l, k) => {
      if(Array.isArray(l.options)) {
        let options = {}
        l.options.forEach(o => {
          if(typeof o === 'string') o = { value: o, label: o }
          if(!o.include) o.include = 'Y'
          options[o.value] = o
        })
        l.options = options
      }
    })
    _forEach(orgSchema.lists, (l, k) => {
      if(Array.isArray(l.options)) {
        let options = {}
        l.options.forEach(o => {
          if(typeof o === 'string') o = { value: o, label: o }
          if(!o.include) o.include = 'Y'
          options[o.value] = o
        })
        l.options = options
      }
    })



    // MERGE SCHEMAS
    // Merge trakk schema with org schema based on groups defined by orgSchema
    // Each schema object is merged separately so the "org" overwrites selected parts of 
    // the "standard" schema. 
    
    // First compile list of asset types from selected 'groups' and 'types' in orgSchema
    let typesList = new Set();
    let groups = orgSchema.setup?.groups || []
    groups.forEach(key => {
        let types = (stdSchema.groups[key] || {}).types || []
        types.forEach(t => typesList.add(t))
    })
    Object.keys(orgSchema.types || {}).forEach(key => {
        typesList.add(key)
    })

    // Compile a list of reports in use
    let commonReports = ['note', 'fault', 'condition']
    let reportsList = new Set(commonReports)
    let features = orgSchema.setup?.features || [];
    if(features.includes('tasks')) {
      reportsList.add('task_reactive')
      reportsList.add('task_scheduled')
    }
    if(features.includes('nz_bwof')) {
      // reportsList.add('task_bwof_single')
      reportsList.add('task_bwof_iqp')
      reportsList.add('task_bwof_insp')
    }
    Object.keys(orgSchema.reports || {}).forEach(key => {
      reportsList.add(key)
    })
    
    // Then compile a list of templates and attributes used by those asset types
    let templateList = new Set()
    
    // Add common attributes, amsID and all attributes configured by orgSchema
    let attributeList = new Set(stdSchema.commonAttributes)
    if(schema.setup.amsID) attributeList.add(schema.setup.amsID)
    if(Array.isArray(orgSchema.attributes)) {
      // convert orgSchema.attributes to object
      let orgAttributes = {}
      orgSchema.attributes.forEach(a => {
        orgAttributes[a.key] = a
      })
      orgSchema.attributes = orgAttributes
    }
    Object.keys(orgSchema.attributes || {}).forEach(k => { 
      attributeList.add(k)
    })
    
    // process reports and collect their attributes
    reportsList.forEach(key => {
      let orgReport = orgSchema.reports[key] || {}
      let tak = typeof orgReport.tak === 'undefined' ? key : orgReport.tak
      let report = {}
      // console.log('report', tak, stdSchema.reports[tak])
      if(stdSchema.reports[tak]) Object.assign(report, stdSchema.reports[tak], { tak, key })
      
      // console.log(key, report)
      // Merge orgReport with nested attributes object
      Object.keys(orgReport).forEach(k => { 
          if(k === 'attributes') {
              if(!report.attributes) report.attributes = {}
              Object.assign(report.attributes, orgReport.attributes)
          }
          else report[k] = orgReport[k] 
      })
      
      // Identify attributes contained in report.fields[] to attributeList
      if(!report.fields?.length) {
        console.warn('Report has no fields:', key)
        report.fields = []
      }
      report.fields.forEach(f => {
        attributeList.add(f.key)
      })
      schema.reports[key] = report
    })
    // console.log('reports', schema.reports)

    // Process types and collect their templates and attributes
    typesList.forEach(key => {
        let orgType = orgSchema.types[key] || {}
        let tak = typeof orgType.tak === 'undefined' ? key : orgType.tak
        let type = {}
        if(stdSchema.types[tak]) Object.assign(type, stdSchema.types[tak], { tak, key })
        // Merge orgType with nested addAttrib object
        Object.keys(orgType).forEach(k => { 
          if(k === 'addAttrib') {
            if(!type.addAttrib) type.addAttrib = {}
            Object.assign(type.addAttrib, orgType.addAttrib)
          }
          else type[k] = orgType[k] 
        })
        // if(type.name === 'Chamber - Sump') console.log(key, type)
        
        if(typeof type.template === 'undefined' || type.template === null) return console.log('WARNING: Asset Type has no Template:', key, type);
        else templateList.add(type.template);
        
        // Convert nested attributes object to array and add to attributeList
        let addAttrib = []
        _forEach(type.addAttrib, (a, k) => {
            if(a.include !== 'Y') return
            k = k.trim()
            addAttrib.push(k)
            attributeList.add(k)
        })
        type.addAttrib = addAttrib

        schema.types[key] = type
    })

    // Process templates and their attributes
    templateList.forEach(key => {
        let orgTemplate = orgSchema.templates[key] || {}
        let tak = typeof orgTemplate.tak === 'undefined' ? key : orgTemplate.tak
        let template = {}
        if(stdSchema.templates[tak]) Object.assign(template, stdSchema.templates[tak], { tak, key })
        
        // Merge orgTemplate with nested addAttrib object
        Object.keys(orgTemplate).forEach(k => { 
            if(k === 'attributes') {
                if(!template.attributes) template.attributes = {}
                Object.assign(template.attributes, orgTemplate.attributes)
            }
            else template[k] = orgTemplate[k] 
        })
        if(!template.attributes) {
          LogEvent('system_error', { key: 'schema:invalidTemplate', details: 'Found invalid schema template: ' + key })
        }
        else stdSchema.common.forEach(a => {
            if(!template.attributes[a]) template.attributes[a] = { include: 'Y' }
        })

        // Convert nested attributes object to array
        let attributes = []
        _forEach(template.attributes || {}, (a, k) => {
            if(a.include !== 'Y') return
            k = k.trim()
            attributeList.add(k)
            attributes.push(k)
        })
        template.attributes = attributes
        schema.templates[key] = template;
    })

    // Process attributes and compile selection lists
    let attributesAdded = new Set()
    attributeList.forEach(key => {
        if(attributesAdded.has(key)) return
        let orgAttribute = orgSchema.attributes[key] || {}
        let tak = typeof orgAttribute.tak === 'undefined' ? key : orgAttribute.tak
        let stdAttribute = stdSchema.attributes[tak] || null

        
        let debug = null
        // debug = (key === 'pid_ref') 
        if(debug) console.log('debug', { orgAttribute, tak, stdAttribute })
        let attribute = {}
        if(stdAttribute) Object.assign(attribute, stdAttribute, { tak })
        Object.assign(attribute, orgAttribute, { key })
        if(['list', 'obj'].includes(attribute.type)) {
            let key = attribute.setup
            if(!schema.lists[key]) {
                let list = { key }
                let stdList = stdSchema.lists[key] || null
                if(stdList) Object.assign(list, stdSchema.lists[key]) 
                  
                // Merge orgList with nested 'options' object
                let orgList = (orgSchema.lists || {})[key] || {}
                Object.keys(orgList).forEach(k => { 
                    if(k === 'options') {
                        if(!list.options) list.options = {}
                        Object.assign(list.options, orgList.options)
                    }
                    else list[k] = orgList[k] 
                })
                
                // Convert options to array
                let options = []
                _forEach(list.options, (o, k) => {
                    if(o.include && o.include !== 'Y') return
                    options.push(o)
                })
                list.options = options
                if(!list.options) return console.log(`schema, found invalid list: '${key}'`);
                schema.lists[key] = list
            }
        }
        schema.attributes.push(attribute)
        attributesAdded.add(tak)
    })

    // Sort attributes based on index
    schema.attributes.sort(function(a, b) {
        if(typeof a.index === 'undefined') return 1
        if(typeof b.index === 'undefined') return -1
        return a.index - b.index;
    });

    // Resolve complete report definitions
    _forEach(schema.reports, (r, k) => {
      schema.reports[k] = reportSetup(r, schema.attributes)
    })
    return { std: stdSchema, org: orgSchema, merge: schema }
}

/**
 * reportDefinition() - merges attribute definitions in report object with schema attributes
 */
export const reportSetup = (report, attributes) => {
  let result = _cloneDeep(report)
  report.fields.forEach(f => {
    if(f.key) {
      let att = _cloneDeep(attributes.find(a => { return a.key === f.key }))
      if(!att) return LogEvent('system_error', { key: 'reportDefinition:attribute', details: `Attribute '${f.key}' not found in schema` })
      Object.assign(att, report.attributes[f.key] || {})
      result.attributes[f.key] = att
    }
  })
  return result
}

/**
 * Promise wrapper for FileReader to read file as text
 */
export const readFileAsText = (file) => {
  return new Promise ((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsText(file)
  })
}

/**
 * Removes organization-specific fields from a schema
 * Used to create a clean standard schema without custom modifications
 */
export const cleanStandardSchema = (schema) => {
  let reduced = {};

  ['attributes','types','templates','lists','groups'].forEach(table => {
    if(schema[table]) {
      reduced[table] = {}
      _forEach(schema[table], (a, key) => {
        // if(tak === 'mat_equip') console.log(a)
        let tak = a.tak || key
        let att = _cloneDeep(a);
        ['tak','amskey','key'].forEach(k => {
          if(att[k]) delete att[k]
        })
        reduced[table][tak] = att
      })
    }
  })

  // Restore any missing lists from standard schema
  if(reduced.lists) {
    let stdLists = store.state.Db.schema.std.lists
    _forEach(stdLists, (list, key) => {
      if(!reduced.lists[key]) reduced.lists[key] = list
      else {
        let newList = reduced.lists[key]
        if(!newList.options || newList.options.length === 0) {
          newList.options = list.options
        }
      }
    })
  }
  return reduced
}

/**
 * Downloads schema tables as CSV files in a zip archive
 * Used for exporting schema data
 */
export const downloadTables = async (scope) => {
    let csvData = {
        attributes: {
          file: "attributes.csv",
          columns: ['key', 'tak', 'label', 'description', 'type', 'setup', 'index'],
          data: []
        },
        types: {
          file: "types.csv",
          columns: ['key', 'tak', 'name', 'description', 'template', 'include', 'addAttrib'],
          data: []
        },
        templates: {
          file: "templates.csv",
          columns: ['key', 'tak', 'name', 'description', 'attributes'],
          data: []
        },
        lists: {
          file: "lists.csv",
          columns: ['tak', 'label', 'description'],
          data: []
        },
        listData: {
          file: "list_data.csv",
          columns: ['list', 'value', 'label', 'description', 'include'],
          data: []
        }
      };
      let data = Object.assign({}, store.state.Db.schema[scope])
      
      if(scope === 'std') {
        csvData.groups = {
          file: "groups.csv",
          columns: ['tak', 'name', 'description', 'types'],
          data: []
        }
        _forEach(data.groups || {}, (group, key) => {
          let d = { tak: key, ...group}
          d.types = group.types.join(' ')
          csvData.groups.data.push(d)
        });
      }
      
      _forEach(data.attributes || {}, (a, key) => {
        let d = { key, ...a}
        if(scope === 'std') d.tak = key
        csvData.attributes.data.push(d)
      })

      _forEach(data.types || {}, (type, tak) => {
        let key = type.key || key
        let d = { key, tak, ...type }

        if(scope === 'merge') d.addAttrib = type.addAttrib.join(' ')
        else {
          d.addAttrib = ''
          _forEach(type.addAttrib, (v, k) => {
            if(d.addAttrib !== '') d.addAttrib += ' '
            if(v.include === 'Y') d.addAttrib += k
          })
        } 

        csvData.types.data.push(d)
      });
      
      _forEach(data.templates || {}, (template, tak) => {
        let key = template.key || tak
        let d = { tak, key, ...template}
        if(scope === 'merge') d.attributes = template.attributes.join(' ')
        else {
          d.attributes = ''
          _forEach(template.attributes, (v, k) => {
            if(v.include === 'Y') {
              if(d.attributes !== '') d.attributes += ' '
              d.attributes += k
            }
          })
        }

        csvData.templates.data.push(d)
      });

      _forEach(data.lists || {}, (list, tak) => {
        let d = { tak, ...list }
        csvData.lists.data.push(d)
        
        if(scope === 'std' || !['man','sup'].includes(tak)) {
          _forEach(list.options, (option) => {
            csvData.listData.data.push({ list: tak, ...option })
          })
        }
      })
      
      let zip = new JSZip()
      Object.keys(csvData).forEach(f => {
        let data = csvData[f].data
        let columns = csvData[f].columns
        let file = csvData[f].file
        let csv = Papa.unparse(data, { columns });
        zip.file(file, csv)
      })
      const blob = await zip.generateAsync({ type: "blob" })
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "application/zip" });
      a.download = `schema_tables_${tsNowString()}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
}

/**
 * Performs the inverse operation of updateSchema() by extracting organization-specific
 * modifications from a final schema.
 * 
 * While updateSchema() merges:
 * standard schema + org modifications -> final schema
 * 
 * This function extracts:
 * final schema - standard schema -> org modifications
 * 
 * It accomplishes this by:
 * 1. Comparing each item in the final schema against the standard schema
 * 2. Using reduceObject() to compute the minimal set of differences
 * 3. Only including properties that differ from the standard schema
 * 4. Preserving special fields like 'tak' when they differ from 'key'
 * 
 * The result is the minimal set of org-specific modifications needed to
 * reproduce the input schema when merged with the standard schema by updateSchema()
 * 
 * @param {Object} schema - The final schema to extract modifications from
 * @returns {Object} The minimal set of org-specific modifications
 */
export const reduceSchema = (schema) => {
    let reduced = {};
    let stdSchema = store.state.Db.schema.std;
    ['attributes','types','templates','lists'].forEach(table => {
      if(schema[table]) {
        reduced[table] = {}
        _forEach(schema[table], (a, key) => {
          let tak = a.tak
          // if(key === 'year') console.log(key, { a })
          if(!a.tak) reduced[table][key] = a
          else {
            let std_v = { key, tak, ...stdSchema[table][a.tak]}
            let diff = reduceObject(std_v, a)
            if(tak !== key) diff.tak = tak
            if(Object.keys(diff).length > 0) reduced[table][key] = diff
            // if(key === 'year') console.log({ std_v, a, diff })
          }
        })
      }
    })
    return reduced
}

/**
 * Loads schema files (CSV or JSON) and processes them into a schema object
 * Handles both single JSON files and multiple CSV files in a zip archive
 */
export const loadFiles = async (files) => {
    let data = {}
    let error = null
    if(files.length === 1 && ['application/x-zip-compressed','application/zip'].includes(files[0].type)) {
        let zip = new JSZip()
        await zip.loadAsync(files[0])
        let zipFiles = []
        let extract = []
        Object.values(zip.files).forEach(async file => {
            extract.push((async () => {
                let type = file.name.split('.').pop()
                let f = { name: file.name, lastModified: file.date }
                if(!['csv','json'].includes(type)) return error = `Invalid file type ${file.name}`
                f.text = await file.async('text')
                if(type === 'csv') f.type = 'application/csv'
                if(type === 'json') f.type = 'application/json'
                zipFiles.push(f)
            })())
        })
        await Promise.all(extract)
        files = zipFiles
    }

    if(files.length === 1 && files[0].type === 'application/json') {
        let text = files[0].text
        if(typeof text !== 'string') text = await text()
        data = JSON.parse(files[0].text)
    }
    else if(files.length > 1) {
        _forEach(files, file => {
            if (!['text/csv','application/csv'].includes(file.type)) {
              console.log('Invalid file type', file.name, file.type)
              error = `Invalid file type ${file.name}, multiple file upload should be CSV`
            }
        })
        if(error) return { data, error }

        // Process files as CSV
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            let text = files[i].text
            if(typeof text !== 'string') text = await readFileAsText(files[i])
            let csv = Papa.parse(text, { header: true })
            let table = file.name.split('.')[0]
            // Parse csv data to object, include 'groups' for use only in std schema
            if(['attributes','templates','types','lists','groups'].includes(table)) {
                if(!data[table]) data[table] = {}
                csv.data.forEach(row => {
                    let key = row.key || row.tak
                    data[table][key] = Object.assign(data[table][key] || {}, row)
                })
            }
            if(table === 'list_data') {
                if(!data.lists) data.lists = {}
                csv.data.forEach(row => {
                    if(!data.lists[row.list]) data.lists[row.list] = {}
                    if(!data.lists[row.list].options) data.lists[row.list].options = []
                    let value = row.value
                    if(!isNaN(value)) value = Number(value)
                    data.lists[row.list].options.push({ value, label: row.label })
                })
            }
        }
    }

    // convert selected fields
    // space deliminated text to arrays (types, addAttrib and attributes)
    // index to number
    _forEach(data.types, type => { 
      type.addAttrib = dimmToArray(type.addAttrib)
      if(type.life_yr) type.life_yr = parseInt(type.life_yr)
    })
    _forEach(data.templates, template => { template.attributes = dimmToArray(template.attributes) })
    _forEach(data.groups, group => { group.types = dimmToArray(group.types) })
    _forEach(data.attributes, attribute => {
        if(typeof attribute.index === 'string') attribute.index = parseInt(attribute.index)
    })
    

    // remove blank and inactive flags, and blank table entries
    _forEach(data, (table, k) => {
        _forEach(table, (row, key) => {
            // if key is blank or undefined, remove it from the table
            if(!key || key === '' || key === 'undefined') {
              console.warn('skipping import row with blank key in: ' + k)
              delete table[key]
            }
            
            // description and label blank values do not overwrite std schema
            if(row.description === '') delete row.description
            if(row.label === '') delete row.label
            // 'include' flag blank or 0 is removed
            if([0,'','0'].includes(row.include)) delete row.include

            // if object is blank, remove it from the table
            if(Object.keys(row).length === 0) delete table[key]
        })
    })

    // CHECK FOR ERRORS or MISSING DATA
    // check list data has config and options
    let listError = null
    _forEach(data.lists, (list, tak) => {
        if(!list.options) {
            if(['man', 'man_nz'].includes(tak)) {
              list.options = []
              return console.log(`'${tak}' has no options, assume no changes`)
            }
            listError = `Error loading list '${tak}' no options, check list_data.csv`
            console.warn(listError)
        }
        else if(!list.tak) {
            listError = `Error loading list '${tak}' missing config, check list.csv`
            console.warn(listError)
        }
    })
    if(listError) {
        error = listError
        delete data.lists
    }
    let filesError = null;
    ['attributes','types','templates','lists'].forEach(table => {
      if(!data[table]) {
        filesError = `Missing table '${table}' schema load must include all tables in order to resolve attributes`
        console.warn(filesError)
      }
    })
    if(filesError) {
      error = filesError
      ['attributes','types','templates','lists'].forEach(table => {
        delete data[table]
      })
    }

    // console.log('Schema Load:', {data, error})
    return { data, error }
}
