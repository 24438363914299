// import router from '@/router'
import { setDoc, bindCollection, bindDocument, outsetaAddUser, outsetaGetToken } from '../firebase.js'
import _cloneDeep from "lodash/cloneDeep";
import LogRocket from 'logrocket';
import OutsetaTasks from "@/store/outsetaTasks"

var timers = {
  mapState: null
}
const envMode = process.env.NODE_ENV
            
export default {
  state: {
    user: {
      loggedIn: false,
      data: { verified: false },
    },
    configUser: {},
    configOrgs: [],
    featureGates: {
      'pdf-full-demo': false
    },
    featureSelect: [
      'stageEdit', 
      'stageCond', 
      'externalFiles'
    ],
    authReady: false
  },
  getters: {
    isAdmin(state, getters, rootState) {
      let admin = false
      if (Array.isArray((rootState.Db.config || {}).admin)) {
        admin = rootState.Db.config.admin.includes(state.user.data.uid)
      }
      return admin
    },
    orgConfig(state, getters, rootState) {
      return ((rootState.Db.org || {}).users || {})[state.user.data.uid] || {}
    },
    orgSettings(state, getters, rootState) {
      let orgID = (rootState.Db.org || {}).id
      let orgsSettings = state.configOrgs || []
      let settings = orgsSettings.find(os => { return os.id === orgID })
      return settings || {}
    },
    unitSettings(state, getters, rootState) {
      let unitID = (rootState.Db.unit || {}).id
      if (!unitID) return {}
      return (getters.orgSettings.unit || {})[unitID] || {}
    },
  },
  mutations: {
    AUTH_READY(state, value) {
      // console.warn('AUTH_READY', value)
      state.authReady = value
    },
    SET_LOGGED_IN(state, value) {
      // console.warn('SET_LOGGED_IN', value)
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_GATE(state, {key, value}) {
      state.featureGates[key] = value
    }
  },
  actions: {
    nav({ dispatch, getters, rootState }, nav) {
      if(!nav.asset && !nav.image && !nav.report) return
      let unitSettings = getters.unitSettings || {}
      let recent = _cloneDeep(unitSettings.recent || {})
      if(!recent.assets) recent.assets = []
      if(!recent.images) recent.images = []
      if(!recent.reports) recent.reports = []

      if(nav.asset) {
        let asset = getters.assetByID(nav.asset)
        if(asset) {
          // insert/move asset at top of list
          let recentAsset = {
            id: asset.id,
            type: asset.attributes.type || null,
            assetID: asset.assetID || null,
            root: asset.root || null,
            label: asset.attributes.label || null,
            position:  asset.attributes.position || null,
          }
          let id = asset.id
          let assetIndex = recent.assets.findIndex(a => a.id === id)
          if(assetIndex > -1) recent.assets.splice(assetIndex, 1)
          recent.assets.splice(0, 0, recentAsset)
          if(recent.assets.length > 10) recent.assets.pop()
        }
      }
      if(nav.image) {
        let image = getters.imageByID(nav.image)
        if(image) {
          let id = image.id
          let recentImage = {
            id: image.id,
            imageID: image.imageID,
            assets: image.assets || [],
            roots: image.roots || [],
            type: image.type,
            thumb: image.thumb || null,
            label: image.attributes?.label || null,
            position:  image.attributes?.position || null,
          }
          let imageIndex = recent.images.findIndex(i => i.id === id)
          if(imageIndex > -1) recent.images.splice(imageIndex, 1)
          recent.images.splice(0, 0, recentImage)
          if(recent.images.length > 10) recent.images.pop()
        }
      }
      if(nav.report) {
        let report = rootState.Db.reports[nav.report]
        // console.log('recent:add', report)
        if(report) {
          let id = report.id
          let recentReport = {
            id: report.id,
            type: report.type,
            title: report.attributes?.title || null,
            description:  report.attributes?.description || null,
            status: report.status?.status || null,
            userID: report.userID || report.status?.userID || null,
            ts: report.ts,
          }
          let reportIndex = recent.reports.findIndex(r => r.id === id)
          if(reportIndex > -1) recent.reports.splice(reportIndex, 1)
          recent.reports.splice(0, 0, recentReport)
          if(recent.reports.length > 10) recent.reports.pop()
        }
      }
      dispatch('updateUnitSettings', { recent })
    },
    switchOrg({ state, dispatch }, id) {
      if (!id || id === null) return console.warn('invalid org', id)
      if (state.configUser.org !== id) {
        console.log('switch org:', state.configUser.org, id)
        dispatch('updateUserConfig', { org: id })
      }
    },
    switchUnit({ dispatch, getters }, id) {
      if (!id || id === null) return
      if (getters.orgSettings.currentUnit !== id) {
        console.log('switch unit:', getters.orgSettings.currentUnit, id)
        dispatch('updateOrgSettings', { currentUnit: id })
      }
    },
    setUser: async ({ commit, dispatch, state }, user) => {
      if (user) {
        commit("SET_USER", user);
        // Update user login data
        if(String(window.location.href).endsWith('login.html')) window.location.href = '/'
        
        if (!user.offline) dispatch('updateUserConfig', { loginTS: user.loginTS, refreshTS: user.refreshTS, passwordTS: user.passwordTS })
        await bindCollection({ key: 'Auth.configOrgs', collectionRef: ['users', state.user.data.uid, 'orgs'] })
        await bindDocument({ key: 'Auth.configUser', documentRef: ['users', state.user.data.uid] })
        let loadUser = async () => {
          let config = state.configUser
          if(!config.outsetaUID) outsetaAddUser().then(uid => { console.log(uid) })
          if((config || {}).id) {
            commit('AUTH_READY', true)
            dispatch('loggedIn')
            
            if(envMode !== 'development') LogRocket.identify(config.name + "_" + user.uid, {
              uid: user.uid,
              name: config.name,
              email: config.email,
            });
          } else {
            // config.org has not loaded yet, wait and then try again. This shouldn't happen.
            console.warn('configUser is taking a long time to load')
            setTimeout(loadUser, 1000)
          }
        }
        loadUser()
      } else {
        commit("SET_USER", {});
        commit('AUTH_READY', true)
        commit("SET_LOGGED_IN", false);
        dispatch('loading', false)
      }
    },
    loggedIn: ({ commit, dispatch, state }) => {
      let org = state.configUser.org
      commit("SET_LOGGED_IN", true);
      if(!org) {
        console.log('auth.js newUser')
        commit('SET_NAV', { waiting: '/account' })
        dispatch('loading', false)
      }
    },
    logout({ commit, dispatch }) {
      commit("SET_LOGGED_IN", false);
      commit("SET_USER", {
        displayName: false,
        verified: false,
        uid: false
      });
      dispatch('loggedOut')
      OutsetaTasks.logout()
      // router.push('/login')
    },
    hideTip({ state, }, code) {
      let clearTips = state.configUser?.clearTips || []
      if(!clearTips.includes(code)) {
        clearTips = [ ...clearTips, code]
        setDoc([`users`, state.user.data.uid], { clearTips })
      }
    },
    updateUserConfig({ state }, update) {
      setDoc([`users`, state.user.data.uid], update)
    },
    updateOrgSettings({ state, rootState }, update) {
      setDoc(['users', state.user.data.uid, 'orgs', rootState.Util.org], update)
    },
    updateUnitSettings({ state, rootState }, update) {
      let unitID = rootState.Db.unit.id
      // console.log('updateUnitSettings', update)
      if (unitID) {
        let orgUpdate = { unit: {} }
        orgUpdate.unit[unitID] = update
        setDoc(['users', state.user.data.uid, 'orgs', rootState.Db.org.id], orgUpdate)
      }
    },
    saveMapState({ dispatch, rootState }, mapState) {
      // console.log('saveMapState', mapState)
      if (timers.mapState !== null) clearTimeout(timers.mapState)
      let update = { mapState }
      timers.mapState = setTimeout(() => {
        dispatch('updateUnitSettings', update)
      }, 5000)
    }
  }
}
