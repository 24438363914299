/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from '@/store'

/*
if(true) {
  */
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (reg) {
      console.log('SW active')
      store.commit('SET_SW', reg)
    },
    registered (reg) {
      console.log('SW registered, will check for updates hourly.')
      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        reg.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (reg) {
      console.log('SW upgrade pending. Press (Alt + U) to upgrade.')
      store.commit('SET_SW', reg)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
